.userOptions {
    right: 40px;
    /* background-color: #528b46; */
    background-color: #528b46;

    /* border: 3px solid #FAFAFA; */
    border-top: none;
    /* border-radius: 0px 0px 3px 3px; */
    padding-left: 7px;
    padding-top: 8px;
    color: #FAFAFA;
    /* color: black; */
    border-radius: 0px 6px 6px 0; 
    height: 40px;
    z-index: 33;
    width: 320px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.userOptions-app {
    background-color: #528b46;
    color: #FAFAFA;
    /* color: black; */
    height: 7vh;
    min-height: 50px;
    z-index: 33;
    flex: 1;
    display: flex;
    align-items: center;
    border-bottom: rgba(0, 0, 0, 0.19) solid 2px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.userName {
    display: inline;
}

.userOptions button {
    margin-left: 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: #FAFAFA;
    /* color: black;     */
    float: right;
}

.userOptions-app button {
    margin-left: 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: #FAFAFA;
    /* color: black;     */
    float: right;
}

.userOptions button:hover {
    border-radius: 2px;
    border: 1px solid #FAFAFA;
    /* border: 1px solid black; */

    color: #FAFAFA;
    /* color: black; */

    margin-left: 19px;
    margin-right: 4px;
    margin-top: -1px;
}

.iconRed {
    /* padding: 3px; */
    /* margin-right: 5px; */
    -webkit-transition-duration: 0s;
    /* Safari */
    transition-duration: 0s;
    /* color: #722f37 */
    color: #FAFAFA
}

.divAlerta {
    background: #fa0c01;
    color: #000000;
    width: 15px;
    height: 15px;
    /* line-height:30px; */
    /* vertical-align:middle; */
    text-align: center;
    /* font-size:10px; */
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    margin-left: -10px;
    padding-top: 3px;

}

.divAlertaAniver {
    background: #000000;
    color: #FAFAFA;
    width: 15px;
    height: 15px;
    /* line-height:30px; */
    /* vertical-align:middle; */
    text-align: center;
    /* font-size:10px; */
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    margin-left: -10px;
    padding-top: 3px;

}


.fr_icon_navbar-app {
    height: 30px;
}


.title-app {
    display: flex;
    align-items: center;
    flex: 1;
}

.titleText-app {
    flex: 1;
    margin-left: 0;
    padding-left: 0;
    text-indent: 0;
    margin-bottom: 0;
}