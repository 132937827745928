.tableEdit,
.tableDelete,
.tableOk {
    padding: 3px;
    margin-right: 5px;
    -webkit-transition-duration: 0s;
    /* Safari */
    transition-duration: 0s;
    color: black
}

.tableIcon {
    padding: 3px;
    /* margin-right: 5px; */
    -webkit-transition-duration: 0s;
    /* Safari */
    transition-duration: 0s;
    color: black
}

.tableOkRed {
    padding: 3px;
    margin-right: 5px;
    -webkit-transition-duration: 0s;
    /* Safari */
    transition-duration: 0s;
    color: red
}

.tableEdit:hover,
.tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active,
.tableDelete:active {
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
}

.tableCell {
    padding: '1px'
}

.DetailScrollWrapper {
    overflow-x: auto;
    max-width: 100vw;
    border: 2px solid #528b46;
    -webkit-overflow-scrolling: touch;
}

.DetailOfetasList {
    display: inline-flex;
    height: 30px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;
}