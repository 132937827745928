.tableEdit,
.tableDelete {
    padding: 2px !important;
    margin-right: -1px !important;
    -webkit-transition-duration: 0s;
    /* Safari */
    transition-duration: 0s;
    color: black
}

.tableDeleteRed {
    padding: 3px;
    /* margin-right: 5px; */
    -webkit-transition-duration: 0s;
    /* Safari */
    transition-duration: 0s;
    color: red
}

.tableCell {
    padding: 2px !important;
}