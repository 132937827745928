.Manut {
    display: flex;
    background-color: #FAFAFA;
}

.ManutContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.ManutContentModal {
    padding: 30px;
    padding-bottom: 5px;
    background-color: #FAFAFA;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.Calendar {
    padding: 10px;
    padding-bottom: 10px;
    background-color: #528b46;
    flex: 1;
    height: 100%;
    max-height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 300px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: smaller;

}

.hide-Calendar {
    display: none
}

.Manut-app {
    display: flex;
    background-color: #FAFAFA;
}

.ManutContent-app {
    background-color: #FAFAFA;
    flex: 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.agendaTitle-app {
    align-items: center;
    vertical-align: middle;
    text-align: center;
    margin-bottom: 0px;
    padding-top: calc(7vh + 10px);
    align-self: center;
}


.agendaTitle {
    align-self: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    border-radius: 4px;

}

.filter-app {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100vw;
    height: 100%;
    align-content: stretch !important;
    align-items: stretch !important;
}