@import url('https://fonts.googleapis.com/css2?family=Mitr&family=Saira&display=swap');

*,
html {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Saira', sans-serif;
  -webkit-touch-callout: none;
}

h1,
h2,
h3,
h4 {
  font-family: 'Saira', sans-serif;
}


html {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  font-size: 0.8rem;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.defaultHeader {
  display: flex;
}


.defaultHeader h3 {
  flex: 1;
  background-color: #528b46;
  border-radius: 6px 0 0 6px;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;

}

/* .defaultHeader h4 {
  flex: 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-indent: 20px; */



.defaultHeader h5 {
  flex: 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flow-root;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
}


.labelHeader {
  flex: 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  /* min-height: 15px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-indent: 5px;
  padding-left: 60px;
  padding-top: 5px;
}


.labelResidentes {
  flex: 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.3rem;
  /* min-height: 15px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-indent: 20px; */
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 20px;
  margin-left: 0px;
}


.defaultHeader h6 {
  flex: 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flow-root;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
}

.defaultHeader h3 strong {
  text-indent: 3px;
}


.defaultHeader-app {
  display: flex;
  width: 100vw;
  position: absolute;
}

.defaultHeader-app h3 {
  flex: 1;
  /* margin-right: 10px; */
  /* background-color: #528b46; */
  background-color: #528b46;
  color: #FAFAFA;
  /* color: black; */

  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 0;
}

.defaultHeader-app h3 strong {
  text-indent: 3px;
}

iframe {
  pointer-events: none;
}

iframe.embed-responsive-item {
  pointer-events: all;
}

.my-swal {
  top: 1px;
  position: absolute;
  z-index: 20000 !important;
}