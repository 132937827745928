.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid rgba(189, 183, 107, 0.733);

    /* color: #528b46; */
    color: khaki;

}

.tableEdit:active, .tableDelete:active {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
}

.Modal {
	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(0, 0, 0, 0.5);
    /* overflow-y: auto; */
    border: 3px solid #528b46;

}


.ModalBg {
	/* background: #528b46; */
    color:  black;   
    border: 1px solid #528b46;
    width: auto;

}

.ModalBody {
	/* z-index: 1500; */
	/* margin-left: 10%; */
	margin-bottom: 2px;
	width: 150%;
	height: auto;
	text-align: left;
}

.ModalHeader {
	/* z-index: 1500; */
	/* margin-left: 10%; */
	margin-top: 1px;
	width: 80%;
	height: auto;
	text-align: center;
}
