.tableEdit {
    padding: 1px;
    margin-right: 2px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    color: black
}

.tableDelete {
    padding: 1px;
    margin-right: 2px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid rgba(189, 183, 107, 0.733);

    /* color: #528b46; */
    color: khaki;

}

.tableEdit:active, .tableDelete:active {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
}