.defaultReg {
    display: flex;
    background-color: #FAFAFA;
}

.defaultRegContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

.tableDiv {
    padding-top: 0;
}

#searchForm button:not(.MuiButtonBase-root) {
    margin-bottom: 0;
}

#searchForm i {
    padding-right: 5px;
    /* padding-top: 2px; */
}

.regButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
}

.regButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 80px;
    /* background-color: #528b46; */
    background-color: #528b46;
    color: white;
    border-radius: 4px;
    /* color: black; */

    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: black;
}

.regButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.defaultRegContent form {
    padding-bottom: 40px;
    border-bottom: 2px solid;
    /* border-bottom: 2px solid #528b46; */
    border-bottom: 2px solid #528b46;

}

.selectFileButton {
    border: none;
    width: 150px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}


.incluiFileButton,
.downloadFile {
    border: none;
    width: 100px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.resumoButton {
    border: none;
    width: 150px;
    height: auto;
    background-color: #528b46;
    color: white;
    height: 35px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.sweet-botaoSim {
    background-color: #528b46;


}


.sweet-botaoNao {
    background-color: red;


}

#html_btn,
#html_btn_1,
#html_btn_2,
#html_btn_3,
#html_btn_4 {
    display: none;
}

.Servicos {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
    width: 300px;

}

.regButtonsTab {
    display: inline-flex;
    overflow: auto;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 50px;

}

.regFoto {
    display: inline-flex;
    overflow: auto;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
    margin-bottom: 10px;

}


.regFoto button:not(.MuiButtonBase-root) {
    border: none;
    width: 30px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-top: 10px;
}

.teste {
    display: inline-block;
    overflow: auto;
    /* columns: 100px 2; */
    /* align-items: flex-end; */
    /* align-content: flex-end; */
    /* justify-content: flex-end; */
    /* margin-left: 430px; */

}

.regButtonsTab button:not(.MuiButtonBase-root) {
    border: none;
    width: 30px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-top: 10px;
}

.grupoEdits {
    border: 2px solid #528b46;
    padding: 20px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    width: auto;
}

.grupoTitulo {
    flex: 1;
    background-color: #528b46;
    color: white;
    white-space: normal;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 1px;
    font-weight: 500;
    padding-top: 10px;
    display: flex;
    vertical-align: middle;
    width: auto;
}

.manutReg {
    flex: 1;
    display: flex;
}

.manutReg .register {
    flex: 1;
    /* background-color: #003459; */
    border: 1px solid #528b46;
    margin-left: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.manutReg .register .buttons {
    /* height: 25px; */
    align-self: flex-end;
    margin-bottom: 10px;
}

.manutReg .register .fields {
    flex: 1;
    /* padding-left: 10px; */
}


.grupoEdits button {
    padding: 5px;
    border: none;
    background-color: #528b46;
    color: #F7EFEF;
    margin-left: 10px;
    margin-bottom: 10px;
    transition: all .1s ease-in-out;

}

.manutReg .register .buttons button:hover {
    color: #B8B2B2;
    outline: 1px solid #B8B2B2;
    outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
    padding: 5px;
    border: none;
    background-color: #586879;
    color: #F7EFEF;
    margin-left: 10px;
    transition: all .1s ease-in-out;
}

.flexImageFields {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    /* overflow-y: auto; */
    overflow: auto;

    /* justify-content: stretch; */

}



.defaultReg-app {
    display: flex;
    background-color: #FAFAFA;
}

.defaultRegContent-app {
    background-color: #FAFAFA;
    flex: 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.reg-app {
    margin: 20px;
    padding-top: calc(7vh + 10px);
}

.regButtonsDiv-app {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 20px;
}

.regButtonsDiv-app button:not(.MuiButtonBase-root) {
    border: none;
    width: 80px;
    /* background-color: #528b46; */
    background-color: #528b46;
    color: white;
    /* color: black; */

    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv-app button:hover:not(.MuiButtonBase-root) {
    color: black;
}

.regButtonsDiv-app button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.fotoEvolucao {
    height: 100%;
    /* border: 1px solid; */
    /* border-radius: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #528b46; */

}

.formReceita {
    border: 3px solid #528b46;
    /* background-color: #528b46; */

}


.divReceita {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
}