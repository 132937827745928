@import url(https://fonts.googleapis.com/css2?family=Mitr&family=Saira&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.Login {
    flex: 1 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login::after {
    content: "";
    background-image: url(/primavera/static/media/wallpaper.c09a4203.jpg);
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    flex: 1 1;
    height: 100vh;
    display: flex;
}


.Login .logo {
    margin-top: 15px;

}

.Login p {
    margin-top: -15px;
    margin-bottom: 40px;
}


.Login .form {
    background-color:#528b46;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 5px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 5px;
    border:rgba(0, 0, 0, 0.19) solid 2px;
}


.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(0.9rem + 0.9rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(0.9rem);
    padding-bottom: calc(var(--input-padding-y));
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(0.9rem / 2);
    padding-top: calc(var(--input-padding-y) / 2);
    padding-bottom: calc(0.9rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #000000;
}

:root {
    --input-padding-x: 1.2rem;
    --input-padding-y: 0.9rem;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 0;
    color: black;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    margin-top: 5px;
}

.form-label-group>input,
.form-label-group>label {
    padding: 0.9rem 1.2rem;
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group {
    position: relative;
    margin-bottom: 10px;
}

.loginButtonText {
    display: inline;
    /* margin-left: 10px; */
}
  
.loginButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.loginButton span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}


.loginButton:hover{
    background-color: white;
}

.loginButton:hover span {
    padding-right: 25px;
}

.loginButton:hover span:after {
    opacity: 1;
    right: 0;
}

.loginText {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.1em;
}
  
.loginInput {
    font-size: 1.1em;
    height: 15px;
    border: 3px solid black;
    border-radius: 3px;
}


.loginButton {
	background-color: white;
	margin-top: 15px;
	border-radius: 3px;
	vertical-align: center;
	text-align: center;
	width: 100%;
    height: 30px;
    min-height: 40px;
    border: 3px solid black;
    padding: 3px;
    color: black;
    transition: all 0.4s;
    cursor: pointer;
    margin-bottom: 15px;
}


.Navbar {
    display: flex;
    width: 230px;
    background-color: #528b46;
    height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 1px;
    font-size: 0.875rem;
}

.Navbar-a {
    display: flex;
    width: 230px;
    background-color: #528b46;
    height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 1px;
    -webkit-animation: grow 0.3s;
            animation: grow 0.3s;
}

.Navbar-hide {
    display: flex;
    width: 80px;
    height: 100vh;
    background-color: #528b46;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 3px;

}

.Navbar-a-hide {
    display: flex;
    width: 80px;
    height: 100vh;
    background-color: #528b46;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 3px;
    -webkit-animation: shrink 0.3s;
            animation: shrink 0.3s;
}


.fr_logo_navbar {
    display: flex;
    flex: 1 1;
    width: 200px;
    vertical-align: center;
}


/* .fr_icon_navbar {
    display: flex;
    flex: 1;
    width: 75px;
    vertical-align: center;
} */

a.atalho.nav-link {
    flex: 1 1;
    color: black;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

a.nav-link:hover {
    color: black;
}

a.nav-link.active:hover {
    color: black;
}

.toggle_navbar {
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    transition-duration: 0.4s;
}


.toggleMenuButton:hover {
    color: black;
}


.hide-toggleMenuButton:hover {
    color: black;
}


.toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 5px;
    justify-content: left;
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.buttonItem {
    background-color: transparent;
    border: none;
    color: white;
    /* display: flex;
    width: 100%;
    align-items: center;
    padding-left: 5px;
    justify-content: left;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-top: 20px;
    
    padding-bottom: 10px; */

    flex: 1 1;
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding-left: 25px;
    transition-duration: 0.4s;
    padding-top: 6px;
    padding-bottom: 6px;

}


.buttonItem:hover {
    color: black;
}

.hide-toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 0px;
    justify-content: center;
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Open {
    background-color: rgb(82, 139, 70);
    border-radius: 2px 2px 0 0;
}

.Open:hover {
    color: black;

}


.toggleMenu {
    border: solid 2px rgb(82, 139, 70);
    border-top: none;
    border-radius: 0px 0px 2px 2px;
}

.toggleMenuItem {
    flex: 1 1;
    color: white;
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding-left: 25px;
    transition-duration: 0.4s;
}

.hide-toggleMenuItem {
    flex: 1 1;
    color: white;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition-duration: 0.4s;
}

@-webkit-keyframes grow {
    0% {
        width: 80px
    }

    100% {
        width: 230px
    }
}

@keyframes grow {
    0% {
        width: 80px
    }

    100% {
        width: 230px
    }
}

@-webkit-keyframes shrink {
    0% {
        width: 230px
    }

    100% {
        width: 80px
    }
}

@keyframes shrink {
    0% {
        width: 230px
    }

    100% {
        width: 80px
    }
}
.userOptions {
    right: 40px;
    /* background-color: #528b46; */
    background-color: #528b46;

    /* border: 3px solid #FAFAFA; */
    border-top: none;
    /* border-radius: 0px 0px 3px 3px; */
    padding-left: 7px;
    padding-top: 8px;
    color: #FAFAFA;
    /* color: black; */
    border-radius: 0px 6px 6px 0; 
    height: 40px;
    z-index: 33;
    width: 320px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.userOptions-app {
    background-color: #528b46;
    color: #FAFAFA;
    /* color: black; */
    height: 7vh;
    min-height: 50px;
    z-index: 33;
    flex: 1 1;
    display: flex;
    align-items: center;
    border-bottom: rgba(0, 0, 0, 0.19) solid 2px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.userName {
    display: inline;
}

.userOptions button {
    margin-left: 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: #FAFAFA;
    /* color: black;     */
    float: right;
}

.userOptions-app button {
    margin-left: 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: #FAFAFA;
    /* color: black;     */
    float: right;
}

.userOptions button:hover {
    border-radius: 2px;
    border: 1px solid #FAFAFA;
    /* border: 1px solid black; */

    color: #FAFAFA;
    /* color: black; */

    margin-left: 19px;
    margin-right: 4px;
    margin-top: -1px;
}

.iconRed {
    /* padding: 3px; */
    /* margin-right: 5px; */
    /* Safari */
    transition-duration: 0s;
    /* color: #722f37 */
    color: #FAFAFA
}

.divAlerta {
    background: #fa0c01;
    color: #000000;
    width: 15px;
    height: 15px;
    /* line-height:30px; */
    /* vertical-align:middle; */
    text-align: center;
    /* font-size:10px; */
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    margin-left: -10px;
    padding-top: 3px;

}

.divAlertaAniver {
    background: #000000;
    color: #FAFAFA;
    width: 15px;
    height: 15px;
    /* line-height:30px; */
    /* vertical-align:middle; */
    text-align: center;
    /* font-size:10px; */
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    margin-left: -10px;
    padding-top: 3px;

}


.fr_icon_navbar-app {
    height: 30px;
}


.title-app {
    display: flex;
    align-items: center;
    flex: 1 1;
}

.titleText-app {
    flex: 1 1;
    margin-left: 0;
    padding-left: 0;
    text-indent: 0;
    margin-bottom: 0;
}
.Home {
    display: flex;
}

.HomeContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;   
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
}

.HomeContent a.nav-link {
    background-color: #528b46;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    color: #FAFAFA;   
}

#button {
    background-color: #528b46;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: #FAFAFA;
    

    text-align: center;
}

.linha {
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
    flex-direction:row;
    align-items:center;
    width: 100%;
}

.HomeOptions {
    margin-bottom: 30px;
    margin-top: 30px;
}

.atalhos {
    display: flex;
    justify-content: stretch;
    flex-wrap:wrap;
    flex-direction:row;
    align-items:center;

}

.atalhosBotoes {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.atalho { 
    border-radius: 8px;
}

.gridAtalhos{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.atalhoVazio {
    
    
    text-align: center;
    background-color: rgb(102, 22, 22);
    display: flex;
    flex-direction: column;

}

.Home-app {
    display: flex;
    flex:1 1;
}

.HomeContent-app {
    background: #F2F2F2;  /* fallback for old browsers */

    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
}

.HomeContent-app .atalhos {
    padding: 30px;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    flex: 1 1;
    margin:0;
    padding-top: calc(7vh + 20px);
}

.HomeContent-app .atalhos a.nav-link {
    background-color: #528b46;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    color: #FAFAFA;   

    margin-top: 0;
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 5px;
    border:rgba(0, 0, 0, 0.19) solid 2px;

    margin-right: 0;
}

.coluna{
    display: flex;
    height: 25vh;
    min-width: 80px;
    margin: 10px 5px;
    justify-content: center;
    flex:1 1;
}

.HomeContent-app .atalho {
    max-width: none;
    text-align: center;
}

.HomeContent-app .atalhos a.nav-link:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.HomeContent-app .atalhos a.nav-link:active {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
}


.footerDelphus {
  vertical-align: bottom;
  align-self: flex-end;
  flex: 1 1;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 0.7rem;
  margin-top: 20px;
}


.footerDelphus-app {
  vertical-align: bottom;
  align-self: flex-end;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 0.7rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
.FrLoading {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.tableEdit, .tableDelete, .tableOk {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}

.tableOkRed {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: red
}

.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.DetailScrollWrapper {
    overflow-x: auto;
    max-width: 80vw;
    border: 3px solid #528b46;
    -webkit-overflow-scrolling: touch;
}

.DetailOfetasList {
    display: inline-flex;
    height: 270px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;
}

.DetailScrollWrapper {
    overflow-x: auto;
    max-width: 80vw;
    border: 3px solid #528b46;
    -webkit-overflow-scrolling: touch;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;


}

.DetailOfetasList {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;
}
.tableEdit,
.tableDelete {
    padding: 2px !important;
    margin-right: -1px !important;
    /* Safari */
    transition-duration: 0s;
    color: black
}

.tableDeleteRed {
    padding: 3px;
    /* margin-right: 5px; */
    /* Safari */
    transition-duration: 0s;
    color: red
}

.tableCell {
    padding: 2px !important;
}
.filtersDiv {
    border-bottom: 2px solid #528b46;
    padding-bottom: 15px;
}


.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    border-radius: 4px;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}


.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: white;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}

.uploadFile {
    border: none;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
    padding: 5px;
}

.uploadFile:hover:not(.MuiButtonBase-root) {
    color: white;
}

.uploadFile:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}


.showPasswordButton {
    border: none;
    width: 30px;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
}

.showPasswordButton:hover:not(.MuiButtonBase-root) {
    color: white;
}

.showPasswordButton:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.buttonAgenda {
    border: 2px solid #528b46;
    width: 160px;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 3px;
    margin-right: 3px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.filtersDiv-app {
    border-bottom: 2px solid #528b46;
    margin: 20px;
    padding-bottom: 20px;
    padding-top: calc(7vh + 10px);
}


.filtersDiv-app form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.tableDiv-app {
    margin: 20px;
    margin-top: 0;
}

.tableDiv-app-onlyTable {
    margin: 20px;
    margin-top: 80px;
}

.filtersDiv-app form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv-app form button:hover:not(.MuiButtonBase-root) {
    color: white;
}

.filtersDiv-app form button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.Manut {
    display: flex;
    background-color: #FAFAFA;
}

.ManutContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;
    flex: 1 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.ManutContentModal {
    padding: 30px;
    padding-bottom: 5px;
    background-color: #FAFAFA;
    flex: 1 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.Calendar {
    padding: 10px;
    padding-bottom: 10px;
    background-color: #528b46;
    flex: 1 1;
    height: 100%;
    max-height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 300px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: smaller;

}

.hide-Calendar {
    display: none
}

.Manut-app {
    display: flex;
    background-color: #FAFAFA;
}

.ManutContent-app {
    background-color: #FAFAFA;
    flex: 1 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.agendaTitle-app {
    align-items: center;
    vertical-align: middle;
    text-align: center;
    margin-bottom: 0px;
    padding-top: calc(7vh + 10px);
    align-self: center;
}


.agendaTitle {
    align-self: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    border-radius: 4px;

}

.filter-app {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 100vw;
    height: 100%;
    align-content: stretch !important;
    align-items: stretch !important;
}
.defaultReg {
    display: flex;
    background-color: #FAFAFA;
}

.defaultRegContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

.tableDiv {
    padding-top: 0;
}

#searchForm button:not(.MuiButtonBase-root) {
    margin-bottom: 0;
}

#searchForm i {
    padding-right: 5px;
    /* padding-top: 2px; */
}

.regButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
}

.regButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 80px;
    /* background-color: #528b46; */
    background-color: #528b46;
    color: white;
    border-radius: 4px;
    /* color: black; */

    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: black;
}

.regButtonsDiv button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.defaultRegContent form {
    padding-bottom: 40px;
    border-bottom: 2px solid;
    /* border-bottom: 2px solid #528b46; */
    border-bottom: 2px solid #528b46;

}

.selectFileButton {
    border: none;
    width: 150px;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}


.incluiFileButton,
.downloadFile {
    border: none;
    width: 100px;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.resumoButton {
    border: none;
    width: 150px;
    height: auto;
    background-color: #528b46;
    color: white;
    height: 35px;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.sweet-botaoSim {
    background-color: #528b46;


}


.sweet-botaoNao {
    background-color: red;


}

#html_btn,
#html_btn_1,
#html_btn_2,
#html_btn_3,
#html_btn_4 {
    display: none;
}

.Servicos {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
    width: 300px;

}

.regButtonsTab {
    display: inline-flex;
    overflow: auto;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 50px;

}

.regFoto {
    display: inline-flex;
    overflow: auto;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
    margin-bottom: 10px;

}


.regFoto button:not(.MuiButtonBase-root) {
    border: none;
    width: 30px;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-top: 10px;
}

.teste {
    display: inline-block;
    overflow: auto;
    /* columns: 100px 2; */
    /* align-items: flex-end; */
    /* align-content: flex-end; */
    /* justify-content: flex-end; */
    /* margin-left: 430px; */

}

.regButtonsTab button:not(.MuiButtonBase-root) {
    border: none;
    width: 30px;
    background-color: #528b46;
    color: white;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-top: 10px;
}

.grupoEdits {
    border: 2px solid #528b46;
    padding: 20px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    width: auto;
}

.grupoTitulo {
    flex: 1 1;
    background-color: #528b46;
    color: white;
    white-space: normal;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 1px;
    font-weight: 500;
    padding-top: 10px;
    display: flex;
    vertical-align: middle;
    width: auto;
}

.manutReg {
    flex: 1 1;
    display: flex;
}

.manutReg .register {
    flex: 1 1;
    /* background-color: #003459; */
    border: 1px solid #528b46;
    margin-left: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.manutReg .register .buttons {
    /* height: 25px; */
    align-self: flex-end;
    margin-bottom: 10px;
}

.manutReg .register .fields {
    flex: 1 1;
    /* padding-left: 10px; */
}


.grupoEdits button {
    padding: 5px;
    border: none;
    background-color: #528b46;
    color: #F7EFEF;
    margin-left: 10px;
    margin-bottom: 10px;
    transition: all .1s ease-in-out;

}

.manutReg .register .buttons button:hover {
    color: #B8B2B2;
    outline: 1px solid #B8B2B2;
    outline-offset: -2px;
}

.manutReg .register .buttons button:disabled {
    padding: 5px;
    border: none;
    background-color: #586879;
    color: #F7EFEF;
    margin-left: 10px;
    transition: all .1s ease-in-out;
}

.flexImageFields {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
    /* overflow-y: auto; */
    overflow: auto;

    /* justify-content: stretch; */

}



.defaultReg-app {
    display: flex;
    background-color: #FAFAFA;
}

.defaultRegContent-app {
    background-color: #FAFAFA;
    flex: 1 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.reg-app {
    margin: 20px;
    padding-top: calc(7vh + 10px);
}

.regButtonsDiv-app {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 20px;
}

.regButtonsDiv-app button:not(.MuiButtonBase-root) {
    border: none;
    width: 80px;
    /* background-color: #528b46; */
    background-color: #528b46;
    color: white;
    /* color: black; */

    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv-app button:hover:not(.MuiButtonBase-root) {
    color: black;
}

.regButtonsDiv-app button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.fotoEvolucao {
    height: 100%;
    /* border: 1px solid; */
    /* border-radius: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #528b46; */

}

.formReceita {
    border: 3px solid #528b46;
    /* background-color: #528b46; */

}


.divReceita {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
}
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid rgba(189, 183, 107, 0.733);

    /* color: #528b46; */
    color: khaki;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.FrImageEdit {
    flex: 1 1;
    color: #528b46;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    /* display: flex; */
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-right: 10px;
}

.FrImageOptions input {
    display: none;
}

.FrImageContainer {
    display: inline;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 200px;
    padding-left: 10px;
}

.FrImageEdit img {
    display: inline;
    /* flex: 1; */
    height: 200px;
    width: 225px;
    border: 1px solid #528b46;
}

.FrImageOptions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.FrImageOptions button {
    border: none;
    width: calc(50% - 2px);
    background-color: #528b46;
    color: black;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 4px;
    /* margin-left: 10px; */
    /* margin-right: 20px; */
}

.FrImageOptions button:hover {
    color: silver
}

.FrImageOptions button:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid rgba(189, 183, 107, 0.733);

    /* color: #528b46; */
    color: khaki;

}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.Modal {
	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(0, 0, 0, 0.5);
    /* overflow-y: auto; */
    border: 3px solid #528b46;

}


.ModalBg {
	/* background: #528b46; */
    color:  black;   
    border: 1px solid #528b46;
    width: auto;

}

.ModalBody {
	/* z-index: 1500; */
	/* margin-left: 10%; */
	margin-bottom: 2px;
	width: 150%;
	height: auto;
	text-align: left;
}

.ModalHeader {
	/* z-index: 1500; */
	/* margin-left: 10%; */
	margin-top: 1px;
	width: 80%;
	height: auto;
	text-align: center;
}

.buttonF2 {
  background-color: #528b46;
  border: none;
  color: white;
  /* padding: 6px ; */
  border-radius: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;

}

.buttonF2 {
  background-color: #528b46;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;

}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #528b46;
  color: white;
  /* color: black; */

  height: 30px;
  /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 4px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.select {
    /* background-color: #528b46; */
    color: #FAFAFA;
    min-height: 16px;
    height: 30px;
    /* z-index: 20000 !important; */

}

.ant-select-item-option-content {
    font-size: 0.8rem;
    /* z-index: 20000 !important; */

}


.ant-select-selection-item {
    font-size: 1rem;
    /* z-index: 20000 !important; */
}

.ant-select-selection-placeholder {
    color: black;
    font-size: 1rem;
    /* opacity: 1; */
}
.tableEdit, .tableDelete, .tableOk {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}

.tableOkRed {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: red
}

.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.DetailScrollWrapper {
    overflow-x: auto;
    max-width: 100vw;
    border: 2px solid #528b46;
    -webkit-overflow-scrolling: touch;
}

.DetailOfetasList {
    display: inline-flex;
    height: 30px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;
}

.tableEdit {
    padding: 1px;
    margin-right: 2px; /* Safari */
    transition-duration: 0s;
    color: black
}

.tableDelete {
    padding: 1px;
    margin-right: 2px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid rgba(189, 183, 107, 0.733);

    /* color: #528b46; */
    color: khaki;

}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.tableOk {
    /* padding: 3px; */
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: green
}

.tableOkRed {
    /* padding: 3px; */
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: red
}
.tableEdit:hover, .tableDelete:hover {
    /* padding: 2px; */
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.tableEdit, .tableDelete, .tableOk {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}

.tableOkRed {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: red
}

.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.ImageEdit {
    flex: 1 1;
    color: #528b46;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    /* display: flex; */
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-right: 10px;
}

.ImageOptions input {
    display: none;
}

.ImageContainer {
    display: inline;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 200px;
    padding-left: 10px;
}

.ImageEdit img {
    display: inline;
    flex: 1 1;
    height: 200px;
    width: 300px;
    border: 1px solid #528b46;
}

.ImageOptions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ImageOptions button {
    border: none;
    width: calc(50% - 2px);
    background-color: #528b46;
    color: black;
    height: 30px;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 4px;
    /* margin-left: 10px; */
    /* margin-right: 20px; */
}

.ImageOptions button:hover {
    color: silver
}

.ImageOptions button:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.tableEdit,
.tableDelete,
.tableOk {
    padding: 3px;
    margin-right: 5px;
    /* Safari */
    transition-duration: 0s;
    color: black
}

.tableIcon {
    padding: 3px;
    /* margin-right: 5px; */
    /* Safari */
    transition-duration: 0s;
    color: black
}

.tableOkRed {
    padding: 3px;
    margin-right: 5px;
    /* Safari */
    transition-duration: 0s;
    color: red
}

.tableEdit:hover,
.tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active,
.tableDelete:active {
    /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.tableCell {
    padding: '1px'
}

.DetailScrollWrapper {
    overflow-x: auto;
    max-width: 100vw;
    border: 2px solid #528b46;
    -webkit-overflow-scrolling: touch;
}

.DetailOfetasList {
    display: inline-flex;
    height: 30px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;
}
*,
html {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Saira', sans-serif;
  -webkit-touch-callout: none;
}

h1,
h2,
h3,
h4 {
  font-family: 'Saira', sans-serif;
}


html {
  -webkit-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  font-size: 0.8rem;
}

.App {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.defaultHeader {
  display: flex;
}


.defaultHeader h3 {
  flex: 1 1;
  background-color: #528b46;
  border-radius: 6px 0 0 6px;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;

}

/* .defaultHeader h4 {
  flex: 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-indent: 20px; */



.defaultHeader h5 {
  flex: 1 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flow-root;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
}


.labelHeader {
  flex: 1 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  /* min-height: 15px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-indent: 5px;
  padding-left: 60px;
  padding-top: 5px;
}


.labelResidentes {
  flex: 1 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.3rem;
  /* min-height: 15px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-indent: 20px; */
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 20px;
  margin-left: 0px;
}


.defaultHeader h6 {
  flex: 1 1;
  background-color: #528b46;
  color: #FAFAFA;
  font-size: 1.1rem;
  min-height: 40px;
  display: flow-root;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
}

.defaultHeader h3 strong {
  text-indent: 3px;
}


.defaultHeader-app {
  display: flex;
  width: 100vw;
  position: absolute;
}

.defaultHeader-app h3 {
  flex: 1 1;
  /* margin-right: 10px; */
  /* background-color: #528b46; */
  background-color: #528b46;
  color: #FAFAFA;
  /* color: black; */

  font-size: 1.1rem;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 0;
}

.defaultHeader-app h3 strong {
  text-indent: 3px;
}

iframe {
  pointer-events: none;
}

iframe.embed-responsive-item {
  pointer-events: all;
}

.my-swal {
  top: 1px;
  position: absolute;
  z-index: 20000 !important;
}
