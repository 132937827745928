.buttonF2 {
  background-color: #528b46;
  border: none;
  color: white;
  /* padding: 6px ; */
  border-radius: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;

}

.buttonF2 {
  background-color: #528b46;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: #F7EFEF;

}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: #F7EFEF;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #9c9595; */
  background-color: #528b46;
  color: white;
  /* color: black; */

  height: 30px;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 4px;
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  transform: translateY(4px);
}