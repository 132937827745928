.Home {
    display: flex;
}

.HomeContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;   
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
}

.HomeContent a.nav-link {
    background-color: #528b46;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    color: #FAFAFA;   
}

#button {
    background-color: #528b46;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: #FAFAFA;
    

    text-align: center;
}

.linha {
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
    flex-direction:row;
    align-items:center;
    width: 100%;
}

.HomeOptions {
    margin-bottom: 30px;
    margin-top: 30px;
}

.atalhos {
    display: flex;
    justify-content: stretch;
    flex-wrap:wrap;
    flex-direction:row;
    align-items:center;

}

.atalhosBotoes {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.atalho { 
    border-radius: 8px;
}

.gridAtalhos{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.atalhoVazio {
    
    
    text-align: center;
    background-color: rgb(102, 22, 22);
    display: flex;
    flex-direction: column;

}

.Home-app {
    display: flex;
    flex:1;
}

.HomeContent-app {
    background: #F2F2F2;  /* fallback for old browsers */

    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
}

.HomeContent-app .atalhos {
    padding: 30px;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    flex: 1;
    margin:0;
    padding-top: calc(7vh + 20px);
}

.HomeContent-app .atalhos a.nav-link {
    background-color: #528b46;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #FAFAFA;   

    margin-top: 0;
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 5px;
    border:rgba(0, 0, 0, 0.19) solid 2px;

    margin-right: 0;
}

.coluna{
    display: flex;
    height: 25vh;
    min-width: 80px;
    margin: 10px 5px;
    justify-content: center;
    flex:1;
}

.HomeContent-app .atalho {
    max-width: none;
    text-align: center;
}

.HomeContent-app .atalhos a.nav-link:hover {
    transform: translateY(-2px);
}

.HomeContent-app .atalhos a.nav-link:active {
    transform: translateY(2px);
}

