.Login {
    flex: 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login::after {
    content: "";
    background-image: url('../../imgs/wallpaper.jpg');
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    flex: 1;
    height: 100vh;
    display: flex;
}


.Login .logo {
    margin-top: 15px;

}

.Login p {
    margin-top: -15px;
    margin-bottom: 40px;
}


.Login .form {
    background-color:#528b46;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 5px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 5px;
    border:rgba(0, 0, 0, 0.19) solid 2px;
}


.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y));
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 2);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #000000;
}

:root {
    --input-padding-x: 1.2rem;
    --input-padding-y: 0.9rem;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 0;
    color: black;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    margin-top: 5px;
}

.form-label-group>input,
.form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group {
    position: relative;
    margin-bottom: 10px;
}

.loginButtonText {
    display: inline;
    /* margin-left: 10px; */
}
  
.loginButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.loginButton span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}


.loginButton:hover{
    background-color: white;
}

.loginButton:hover span {
    padding-right: 25px;
}

.loginButton:hover span:after {
    opacity: 1;
    right: 0;
}

.loginText {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.1em;
}
  
.loginInput {
    font-size: 1.1em;
    height: 15px;
    border: 3px solid black;
    border-radius: 3px;
}


.loginButton {
	background-color: white;
	margin-top: 15px;
	border-radius: 3px;
	vertical-align: center;
	text-align: center;
	width: 100%;
    height: 30px;
    min-height: 40px;
    border: 3px solid black;
    padding: 3px;
    color: black;
    transition: all 0.4s;
    cursor: pointer;
    margin-bottom: 15px;
}

