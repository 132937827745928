.ImageEdit {
    flex: 1;
    color: #528b46;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    /* display: flex; */
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-right: 10px;
}

.ImageOptions input {
    display: none;
}

.ImageContainer {
    display: inline;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 200px;
    padding-left: 10px;
}

.ImageEdit img {
    display: inline;
    flex: 1;
    height: 200px;
    width: 300px;
    border: 1px solid #528b46;
}

.ImageOptions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ImageOptions button {
    border: none;
    width: calc(50% - 2px);
    background-color: #528b46;
    color: black;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 4px;
    /* margin-left: 10px; */
    /* margin-right: 20px; */
}

.ImageOptions button:hover {
    color: silver
}

.ImageOptions button:active {
    transform: translateY(4px);
}