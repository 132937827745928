.tableOk {
    /* padding: 3px; */
    margin-right: 5px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    color: green
}

.tableOkRed {
    /* padding: 3px; */
    margin-right: 5px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    color: red
}
.tableEdit:hover, .tableDelete:hover {
    /* padding: 2px; */
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #528b46;
}

.tableEdit:active, .tableDelete:active {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
}