.DetailScrollWrapper {
    overflow-x: auto;
    max-width: 80vw;
    border: 3px solid #528b46;
    -webkit-overflow-scrolling: touch;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;


}

.DetailOfetasList {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    vertical-align: middle;
}