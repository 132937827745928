.select {
    /* background-color: #528b46; */
    color: #FAFAFA;
    min-height: 16px;
    height: 30px;
    /* z-index: 20000 !important; */

}

.ant-select-item-option-content {
    font-size: 0.8rem;
    /* z-index: 20000 !important; */

}


.ant-select-selection-item {
    font-size: 1rem;
    /* z-index: 20000 !important; */
}

.ant-select-selection-placeholder {
    color: black;
    font-size: 1rem;
    /* opacity: 1; */
}