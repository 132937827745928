.filtersDiv {
    border-bottom: 2px solid #528b46;
    padding-bottom: 15px;
}


.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    border-radius: 4px;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}


.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: white;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}

.uploadFile {
    border: none;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
    padding: 5px;
}

.uploadFile:hover:not(.MuiButtonBase-root) {
    color: white;
}

.uploadFile:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}


.showPasswordButton {
    border: none;
    width: 30px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
}

.showPasswordButton:hover:not(.MuiButtonBase-root) {
    color: white;
}

.showPasswordButton:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.buttonAgenda {
    border: 2px solid #528b46;
    width: 160px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-top: 3px;
    margin-right: 3px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.filtersDiv-app {
    border-bottom: 2px solid #528b46;
    margin: 20px;
    padding-bottom: 20px;
    padding-top: calc(7vh + 10px);
}


.filtersDiv-app form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.tableDiv-app {
    margin: 20px;
    margin-top: 0;
}

.tableDiv-app-onlyTable {
    margin: 20px;
    margin-top: 80px;
}

.filtersDiv-app form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    background-color: #528b46;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv-app form button:hover:not(.MuiButtonBase-root) {
    color: white;
}

.filtersDiv-app form button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}