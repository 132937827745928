.Navbar {
    display: flex;
    width: 230px;
    background-color: #528b46;
    height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 1px;
    font-size: 0.875rem;
}

.Navbar-a {
    display: flex;
    width: 230px;
    background-color: #528b46;
    height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 1px;
    animation: grow 0.3s;
}

.Navbar-hide {
    display: flex;
    width: 80px;
    height: 100vh;
    background-color: #528b46;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 3px;

}

.Navbar-a-hide {
    display: flex;
    width: 80px;
    height: 100vh;
    background-color: #528b46;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(82, 139, 70);
    padding-left: 3px;
    animation: shrink 0.3s;
}


.fr_logo_navbar {
    display: flex;
    flex: 1;
    width: 200px;
    vertical-align: center;
}


/* .fr_icon_navbar {
    display: flex;
    flex: 1;
    width: 75px;
    vertical-align: center;
} */

a.atalho.nav-link {
    flex: 1;
    color: black;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

a.nav-link:hover {
    color: black;
}

a.nav-link.active:hover {
    color: black;
}

.toggle_navbar {
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}


.toggleMenuButton:hover {
    color: black;
}


.hide-toggleMenuButton:hover {
    color: black;
}


.toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 5px;
    justify-content: left;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.buttonItem {
    background-color: transparent;
    border: none;
    color: white;
    /* display: flex;
    width: 100%;
    align-items: center;
    padding-left: 5px;
    justify-content: left;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-top: 20px;
    
    padding-bottom: 10px; */

    flex: 1;
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding-left: 25px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    padding-top: 6px;
    padding-bottom: 6px;

}


.buttonItem:hover {
    color: black;
}

.hide-toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 0px;
    justify-content: center;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Open {
    background-color: rgb(82, 139, 70);
    border-radius: 2px 2px 0 0;
}

.Open:hover {
    color: black;

}


.toggleMenu {
    border: solid 2px rgb(82, 139, 70);
    border-top: none;
    border-radius: 0px 0px 2px 2px;
}

.toggleMenuItem {
    flex: 1;
    color: white;
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding-left: 25px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.hide-toggleMenuItem {
    flex: 1;
    color: white;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

@keyframes grow {
    0% {
        width: 80px
    }

    100% {
        width: 230px
    }
}

@keyframes shrink {
    0% {
        width: 230px
    }

    100% {
        width: 80px
    }
}